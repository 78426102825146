import React from 'react';

interface LeadershipProfileProps {
  photo: string;
  name: string;
  role: string;
  linkedinUrl: string;
}

const LeadershipProfile: React.FC<LeadershipProfileProps> = ({
  photo,
  name,
  role,
  linkedinUrl,
}) => {
  return (
    <div className="w-full block text-center">
      <img src={photo} alt="" className="mx-auto w-auto h-64 md:h-96" />
      <div className="border-b border-border-grey" />
      <div className=" px-6 lg:px-md xl:px-0">
        <div className="font-bold text-2xl mb-2 mt-10">{name}</div>
        <p className="mb-5">{role}</p>
        {linkedinUrl && (
          <a
            href={linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block hover:opacity-75 transition-opacity duration-150 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="27"
              fill="none"
              viewBox="0 0 28 27"
            >
              <g clipPath="url(#clip0)">
                <path
                  fill="#007BB5"
                  d="M25.192 0H2.794C1.724 0 .855.848.855 1.895v22.49c0 1.046.87 1.896 1.939 1.896h22.398c1.071 0 1.944-.85 1.944-1.896V1.895C27.136.848 26.263 0 25.192 0z"
                ></path>
                <path
                  fill="#fff"
                  d="M4.75 9.853h3.903v12.541H4.75V9.853zm1.953-6.235A2.26 2.26 0 116.7 8.14a2.26 2.26 0 01.003-4.522zM11.098 9.853h3.736v1.714h.053c.52-.986 1.792-2.026 3.689-2.026 3.946 0 4.675 2.597 4.675 5.975v6.878h-3.897v-6.099c0-1.454-.025-3.325-2.025-3.325-2.029 0-2.338 1.585-2.338 3.221v6.203h-3.893V9.853z"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <path
                    fill="#fff"
                    d="M0 0H26.281V26.281H0z"
                    transform="translate(.855)"
                  ></path>
                </clipPath>
              </defs>
            </svg>
          </a>
        )}
      </div>
    </div>
  );
};

export default LeadershipProfile;
