import React, { useEffect, useLayoutEffect, useState } from 'react';
import { html2react } from 'helpers/parser';
import useWindowSize from 'hooks/use-window-size';

interface Tile {
  value: string;
  label: string;
  color?: string;
}

interface TilesSectionProps {
  title?: string;
  cols?: number;
  tiles: Tile[];
}

const TilesSection: React.FC<TilesSectionProps> = ({
  title,
  cols = 2,
  tiles,
}) => {
  const tileWidth = `md:w-1/2 lg:w-${12 / cols}/12`;
  const [tileHeight, setTileHeight] = useState('auto');
  const { width } = useWindowSize();

  const handleWindowResize = () => {
    const tile = document.getElementById('tile');

    if (!tile) {
      return;
    }

    setTileHeight(`${tile.clientWidth}px`);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    setTimeout(() => {
      handleWindowResize();
    }, 250);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useLayoutEffect(() => {
    handleWindowResize();
  }, [tiles]);

  const getBgColor = (index): string => {
    if (width < 768) {
      return index % 2 === 0 ? 'bg-tile' : 'bg-white';
    }

    if (cols === 2) {
      return index > 0 && index < tiles.length - 1 ? 'bg-tile' : 'bg-white';
    }

    const add = Math.floor(index / (width < 1024 ? 2 : cols)) % 2 === 0 ? 1 : 0;
    const colorIndex = ((index % 2) + add) % 2;

    return colorIndex === 1 ? 'bg-tile' : 'bg-grey';
  };

  return (
    <div className="u-section md:u-container md:flex flex-wrap px-6 lg:px-md xl:px-0">
      {title && (
        <h2 className="mb-16 lg:mb-32 md:px-20 lg:px-44 u-h2 text-center">
          {html2react(title)}
        </h2>
      )}
      {tiles.map((tile, index) => (
        <div
          key={index}
          id={index === 0 ? 'tile' : undefined}
          className={`${
            cols === 2
              ? 'py-16 md:py-32 lg:py-44'
              : 'py-16 md:py-20 lg:py-28 px-8'
          } flex items-center justify-center flex-col text-center ${tileWidth} ${getBgColor(
            index,
          )}`}
          style={{ height: tileHeight }}
        >
          <div
            className={`${
              cols === 2
                ? 'text-4xl md:text-5xl lg:text-6xl xl:text-7xl'
                : 'text-3xl md:text-5xl'
            } font-semibold ${tile.color} mb-2`}
          >
            {tile.value}
          </div>
          <div
            className={`text-lg  ${
              cols === 2 ? 'md:text-2xl lg:text-3xl' : 'md:text-lg'
            } font-semibold leading-tight`}
          >
            {tile.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TilesSection;
