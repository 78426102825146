import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import CompanyPerk from 'components/molecules/CompanyPerk';
import AmericasNetworkSection from 'components/organisms/AmericasNetworkSection';
import CompanyPerksSection from 'components/organisms/CompanyPerksSection';
import ContactHero from 'components/organisms/ContactHero';
import ContactLinksSection from 'components/organisms/ContactLinksSection';
import CtaSection from 'components/organisms/CtaSection';
// import CustomerLogosSection from 'components/organisms/CustomerLogosSection';
import LeadershipSection from 'components/organisms/LeadershipSection';
import OurStorySection from 'components/organisms/OurStorySection';
// import PressReleaseGridSection from 'components/organisms/PressReleaseGridSection';
import TilesSection from 'components/organisms/TilesSection';

const AboutPageContent = ({
  hero,
  ourStory,
  convenienceStoreNetwork,
  leadership,
  kpis,
  officeImage,
  companyPerks,
  cta,
}) => {
  return (
    <>
      <ContactHero
        title={hero?.title}
        background="bg-paragraph"
        inverted
        wide
      />

      <OurStorySection
        title={ourStory?.title}
        column1={ourStory?.column1}
        column2={ourStory?.column2}
        image1Url={ourStory?.image1}
        image2Url={ourStory?.image2}
        quote={ourStory?.quote}
      />

      <AmericasNetworkSection
        title={convenienceStoreNetwork?.title}
        body={convenienceStoreNetwork?.body}
        customers={convenienceStoreNetwork?.customers}
        customersLabel={convenienceStoreNetwork?.customersLabel}
      />

      {/* <CustomerLogosSection /> */}

      <LeadershipSection
        title={leadership?.title}
        profiles={leadership?.profiles}
      />

      <TilesSection tiles={kpis} />

      <img className="u-section w-full h-auto" src={officeImage} alt="Office" />

      <CompanyPerksSection title={companyPerks?.title}>
        {companyPerks?.features?.map(({title, body, image}, index) => (
          <CompanyPerk
            key={index}
            title={title}
            body={body}
            image={image}
            imageSide={index % 2 === 1 ? 'left' : 'right'}
          />
        ))}
      </CompanyPerksSection>

      {/*
      <PressReleaseGridSection
        title="What we’ve been up to"
        items={[
          {
            category: 'Press Release',
            url: '/',
            title: 'Lectus sed cursus eu adipiscing congue. Egestas et.',
            image: '/images/press-release-1.png',
          },
          {
            category: 'Press Release',
            url: '/',
            title: 'Lectus sed cursus eu adipiscing congue. Egestas et.',
            image: '/images/press-release-1.png',
          },
          {
            category: 'Press Release',
            url: '/',
            title: 'Lectus sed cursus eu adipiscing congue. Egestas et.',
            image: '/images/press-release-1.png',
          },
        ]}
      />
      */}

      <ContactLinksSection
        links={[
          { to: '/careers', label: 'Careers' },
          {
            to: '/contact',
            label: 'Contact Us',
            backgroundPosition: 'center right 52px',
          },
          // {
          //   to: '/press',
          //   label: 'Press Center',
          //   backgroundPosition: 'right 0 center',
          // },
        ]}
      />

      <CtaSection headline={cta?.headline} />
    </>
  );
};

export const AboutPreview = ({ entry }) => {
  return <AboutPageContent {...entry.get('data').toJS()} />;
};

const AboutPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const { seo } = frontmatter;

  return (
    <Layout headerBackground="bg-paragraph" headerInverted>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />
      <AboutPageContent {...frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query About2PageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/about/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
        }
        ourStory {
          title
          column1
          column2
          image1
          image2
          quote
        }
        convenienceStoreNetwork {
          title
          body
          customers
          customersLabel
        }
        leadership {
          title
          profiles {
            name
            role
            photo
            linkedinUrl
          }
        }
        kpis {
          label
          value
          color
        }
        officeImage
        companyPerks {
          title
          features {
            title
            body
            image
          }
        }
        cta {
          headline
        }
      }
    }
  }
`;

export default AboutPage;
