import React from 'react';
import LeadershipProfile from 'components/molecules/LeadershipProfile';
import { html2react } from 'helpers/parser';

interface LeadershipProfile {
  photo: string;
  name: string;
  role: string;
  linkedinUrl: string;
}

interface LeadershipSectionProps {
  title: string;
  profiles: LeadershipProfile[];
}

const LeadershipSection: React.FC<LeadershipSectionProps> = ({
  title,
  profiles,
}) => {
  return (
    <div className="u-section">
      <h2 className="text-center u-h2 mb-20 lg:mb-36 px-12 lg:px-md xl:px-0">
        {html2react(title)}
      </h2>

      <div>
        <div className="u-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-0 gap-y-8 md:gap-y-24">
          {profiles.map((profile, index) => (
            <LeadershipProfile key={index} {...profile} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LeadershipSection;
