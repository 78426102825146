import React from 'react';
import { md2react } from 'helpers/parser';

interface OurStorySectionProps {
  title: string;
  column1: string;
  column2: string;
  image1Url: string;
  image2Url: string;
  quote: string;
}

const OurStorySection: React.FC<OurStorySectionProps> = ({
  title,
  column1,
  column2,
  image1Url,
  image2Url,
  quote,
}) => {
  const column2Body = column2
    ? column2.replace(/(?:\r\n|\r|\n)/g, '<br />')
    : '';

  return (
    <div className="u-section overflow-hidden">
      <div className="flex u-container px-6 lg:px-md xl:px-0">
        <div className="flex-2">
          <h2 className="u-h2 mb-8">{title}</h2>
          <div className="md:flex text-mid-grey text-lg">
            <div className="flex-1 md:mr-6">
              <div className="mb-6 prose-lg">{md2react(column1)}</div>
            </div>
            <div className="flex-1 md:ml-6">
              <div className="mb-6 prose-lg">{md2react(column2)}</div>
            </div>
          </div>

          <div
            className="bg-cover bg-top mt-8 md:mt-20 lg:mt-40 h-120"
            style={{ backgroundImage: `url(${image1Url})` }}
          />
        </div>
        <div className="flex-1 relative pl-20 hidden lg:block">
          <div
            className="bg-cover bg-top-left w-96 h-284 absolute"
            style={{ backgroundImage: `url(${image2Url})` }}
          >
            <div className="absolute top-full mt-16 w-80 text-2xl font-semibold text-black">
              {quote}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStorySection;
