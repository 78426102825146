import React from 'react';
import { html2react } from 'helpers/parser';

interface ContactHeroProps {
  title: string;
  body?: string;
  background: string;
  inverted?: boolean;
  wide?: boolean;
  tall?: boolean;
}

const ContactHero: React.FC<ContactHeroProps> = ({
  title,
  body,
  background,
  inverted,
  wide,
  tall,
}) => {
  return (
    <div
      className={`${
        tall ? 'pt-24 pb-28 lg:pt-60 lg:pb-64' : 'pt-20 pb-24 lg:pt-44 lg:pb-48'
      } ${background}`}
    >
      <div className="u-container mx-auto pt-16 md:pt-xl">
        <div
          className={`px-6 lg:px-md  ${
            wide ? 'md:w-10/12 lg:w-8/12' : 'md:w-7/12 lg:w-5/12'
          } ${inverted ? 'text-white' : 'text-dark-grey'}`}
        >
          <h1 className="mb-5 u-h1">{html2react(title)}</h1>
          {body && <div className="text-lg lg:mr-20 lg:text-xl">{body}</div>}
        </div>
      </div>
    </div>
  );
};

export default ContactHero;
