import React from 'react';

interface CompanyPerkProps {
  title: string;
  body: string;
  image: string;
  imageSide?: 'left' | 'right';
}

const CompanyPerk: React.FC<CompanyPerkProps> = ({
  title,
  body,
  image,
  imageSide = 'right',
}) => {
  return (
    <div
      className={`lg:mx-44 flex flex-col-reverse items-center text-center md:text-left ${
        imageSide === 'right' ? 'md:flex-row' : 'md:flex-row-reverse'
      }`}
    >
      <div
        className={`w-full  md:w-6/12 flex-grow-0 flex-shrink-0 ${
          imageSide === 'right' ? 'md:pr-16' : 'md:pl-16'
        }`}
      >
        <h3 className="u-h3 mb-2">{title}</h3>
        <p className="text-lg mb-8 md:mb-0">{body}</p>
      </div>
      <div className="w-full md:w-6/12  flex-grow-0 flex-shrink-0 mb-8 md:mb-0">
        <img className="w-full h-auto" src={image} alt={title} />
      </div>
    </div>
  );
};

export default CompanyPerk;
