import React from 'react';

interface CompanyPerksSectionProps {
  title?: string;
  grey?: boolean;
}

const CompanyPerksSection: React.FC<CompanyPerksSectionProps> = ({
  title,
  grey,
  children,
}) => {
  return (
    <div className={`u-section ${grey ? 'bg-tile py-20 md:py-32' : ''}`}>
      <div className=" u-container px-6 lg:px-md xl:px-0">
        {title && (
          <h2 className="u-h2 mb-12 md:mb-20 text-center md:px-32 lg:px-60 xl:px-72">
            {title}
          </h2>
        )}
        <div className="space-y-8 md:space-y-16">{children}</div>
      </div>
    </div>
  );
};

export default CompanyPerksSection;
