import { Link } from 'gatsby';
import React from 'react';

interface ContactLink {
  to: string;
  label: string;
  backgroundPosition?: string;
  backgroundPositionHover?: string;
}

interface ContactLinksSectionProps {
  links: ContactLink[];
}

const ContactLinksSection: React.FC<ContactLinksSectionProps> = ({ links }) => {
  return (
    <div className="my-12 lg:my-20 text-center md:my-32 text-dark-grey space-y-20">
      {links.map(({ to, label, backgroundPosition }, index) => (
        <div key={index}>
          <Link
            className={`arrow-container relative py-6 md:py-12 lg:py-14 inline-block text-3xl md:text-6xl lg:text-7.5 font-semibold transition-colors`}
            to={to}
          >
            <div
              className={`absolute inset-0 bg-no-repeat ${
                backgroundPosition ? '' : 'bg-center'
              } bg-contain`}
              style={{
                backgroundImage: 'url(/images/arrow.svg)',
                backgroundPosition,
                zIndex: -1,
              }}
            />

            {label}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ContactLinksSection;
