import React from 'react';
import { html2react } from 'helpers/parser';

interface AmericasNetworkSectionProps {
  title: string;
  body: string;
  customers: string;
  customersLabel: string;
}

const AmericasNetworkSection: React.FC<AmericasNetworkSectionProps> = ({
  title,
  body,
  customers,
  customersLabel,
}) => {
  return (
    <div className="u-section u-container px-6 lg:px-md xl:px-0">
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="mb-12 lg:mb-0 md:w-5/12">
          <img
            src="/images/americas-largest-network.svg"
            alt="America's Largest Independent C-Store Network"
            className="w-full h-auto"
          />
        </div>
        <div className="md:w-5/12 md:mr-8">
          <h2 className="u-h2 mb-2">{html2react(title)}</h2>
          <p className="text-lg leading-7 mb-5">{body}</p>
          <div className="text-orange text-4xl lg:text-6xl font-semibold">
            {customers}
          </div>
          <div className="text-lg font-semibold">{customersLabel}</div>
        </div>
      </div>
    </div>
  );
};

export default AmericasNetworkSection;
